<template>
	<div>
		<!--	考勤表-->
		<div id="forms_div2">
			<div style="display: flex">
				<!--	局指选择工资发放标段-->
				<div v-if="GET_BDST === '1'" style="margin-left: 2%">
					<el-select v-model="salary_post" class="m-2" placeholder="请选择工资发放标段" size="large" @change="getInsuranceUser">
						<el-option
							v-for="item in salary_posts"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</div>
				<!--	选择月份-->
				<div>
					<el-date-picker
						v-model="month"
						type="month"
						placeholder="请选择考勤月份"
						format="YYYY-MM"
						value-format="YYYY-MM"
						@change="getInsuranceUser"
					/>
				</div>
				<div v-if="button_flag" style="margin-left: 2%">
					<el-button @click="saveInsurance" type="danger">保存社保</el-button>
				</div>
				<div v-if="!button_flag" style="margin-left: 2%">
					<el-button @click="removeInsurance" type="danger">删除社保</el-button>
				</div>
			</div>

			<!--	人员考勤表-->
			<div style="margin-top: 1%" v-if="button_flag">
				<vxe-table
					border
					height="700"
					:align="allAlign"
					:column-config="{resizable: true}"
					:row-config="{isHover: true}"
					:edit-config="{trigger: 'click', mode: 'cell'}"
					:data="insurance_list">
					<vxe-column type="seq" width="50" fixed="left"></vxe-column>
					<vxe-column field="name" title="姓名" width="125" fixed="left"></vxe-column>
					<vxe-column field="enterpriseAnnuity" title="企业年金" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.enterpriseAnnuity" type="text" placeholder="请输入企业年金"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="housingFund" title="住房公积金" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.housingFund" type="text" placeholder="请输入住房公积金"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="endowmentInsurance" title="基本养老保险" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.endowmentInsurance" type="text" placeholder="请输入基本养老保险"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="unemploymentInsurance" title="失业保险" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.unemploymentInsurance" type="text" placeholder="请输入失业保险"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="medicalInsurance" title="基本医疗保险" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.medicalInsurance" type="text" placeholder="请输入基本医疗保险"></vxe-input>
						</template>
					</vxe-column>
				</vxe-table>
			</div>
			<div style="margin-top: 1%" v-if="!button_flag">
				<vxe-table
					border
					height="700"
					:align="allAlign"
					:column-config="{resizable: true}"
					:row-config="{isHover: true}"
					:edit-config="{trigger: 'click', mode: 'cell'}"
					:data="insurance_list">
					<vxe-column type="seq" width="50" fixed="left"></vxe-column>
					<vxe-column field="name" title="姓名" width="125" fixed="left"></vxe-column>
					<vxe-column field="endowmentInsurance" title="基本养老保险" width="180"></vxe-column>
					<vxe-column field="housingFund" title="住房公积金" width="180"></vxe-column>
					<vxe-column field="enterpriseAnnuity" title="企业年金" width="180"></vxe-column>
					<vxe-column field="medicalInsurance" title="基本医疗保险" width="180"></vxe-column>
					<vxe-column field="unemploymentInsurance" title="失业保险" width="180"></vxe-column>
				</vxe-table>
			</div>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import {setNowMonth2, setNowTimes} from "@/utils/time";

export default {
	name: "",
	components: {Header},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			salary_post: '2标',
			salary_posts: [
				{
					value: '2标',
					label: '2标',
				},
				{
					value: '5标',
					label: '5标',
				}
			],
			insurance_list: [],
			allAlign: 'center',
			month: setNowMonth2(),
			button_flag: true
		}
	},
	created() {
		this.getInsuranceUser()
	},
	methods: {
		getInsuranceUser(){
			if (this.GET_BDST !== '1'){
				this.salary_post = this.GET_BDST
			}
			this.button_flag = true
			this.axios.get('/ucUser/syncInsuranceUser', (response) => {
				if (response.message === '当前月份已生成社保信息，若想修改，请联系科信部!'){
					this.button_flag = false
					this.insurance_list = response.obj
				}else if (response.message === '当月考勤还未上传!'){
					ElMessage.error(response.message)
					this.insurance_list = []
				}else {
					this.insurance_list = response.obj
				}


			},{
				bdst: this.GET_BDST,
				salary_post: this.salary_post,
				month: this.month
			})
		},
		saveInsurance(){
			this.axios.post('/wageInsurance/saveInsurance', (response) => {
				if (response.obj){
					ElMessage.success('保存成功！')
					this.getInsuranceUser()
				}else {
					ElMessage.error('保存失败，请联系科信部!')
				}
			},{
				month: this.month,
				bdst: this.GET_BDST,
				salary_post: this.salary_post,
				insurances: JSON.stringify(this.insurance_list)
			})
		},
		removeInsurance(){
			this.axios.post('/wageInsurance/removeInsuranceByMonth', (response) => {
				if (response.obj){
					ElMessage.success('删除成功！')
					this.getInsuranceUser()
				}else {
					ElMessage.error('删除失败，请联系科信部!')
				}
			},{
				month: this.month,
				bdst: this.GET_BDST,
				salary_post: this.salary_post
			})
		}

	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 750px;
}
</style>