<template>
	<div>
		<!--	考勤表-->
		<div id="forms_div2">
			<div style="display: flex">
				<!--	局指选择工资发放标段-->
				<div v-if="GET_BDST === '1'" style="margin-left: 2%">
					<el-select v-model="salary_post" class="m-2" placeholder="请选择工资发放标段" size="large" @change="getUserDays">
						<el-option
							v-for="item in salary_posts"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</div>
			</div>

			<!--	人员考勤表-->
			<div style="margin-top: 1%">
				<vxe-table
					border
					height="700"
					:align="allAlign"
					:column-config="{resizable: true}"
					:row-config="{isHover: true}"
					:edit-config="{trigger: 'click', mode: 'cell'}"
					:data="kaoqin_list">
					<vxe-column type="seq" width="50" fixed="left"></vxe-column>
					<vxe-column field="realName" title="姓名" width="125" fixed="left"></vxe-column>
					<vxe-column field="partTimeMoney" title="兼职津贴" width="225" fixed="left" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.partTimeMoney" type="text" placeholder="兼职津贴" @blur="savePartTime(row)"></vxe-input>
						</template>
					</vxe-column>
				</vxe-table>
			</div>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import {setNowMonth2, setNowTimes} from "@/utils/time";

export default {
	name: "",
	components: {Header},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			salary_post: '2标',
			salary_posts: [
				{
					value: '2标',
					label: '2标',
				},
				{
					value: '5标',
					label: '5标',
				}
			],
			kaoqin_list: [],
			month_days: '',
			allAlign: 'center',
			month: setNowMonth2(),
			kaoqin: '',
		}
	},
	created() {
		this.getUserDays()
	},
	methods: {
		getUserDays(){
			this.preview_flag = false
			this.axios.get('/ucUser/getPartTime', (response) => {
				this.kaoqin_list = response.obj
			},{
				bdst: this.GET_BDST,
				salary_post: this.salary_post
			})
		},
		savePartTime(row){
			this.axios.post('/wagePartTime/updataPartTime', (response) => {
				if (response.obj){
					ElMessage.success('保存成功!')
				}
			},{
				user_id: row.userId,
				part_time_money: row.partTimeMoney,
				real_name: row.realName
			})
		}

	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 98%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 750px;
}
</style>