<template>
	<div>
		<!--生成按钮 -->
		<div style="display: flex">
			<div v-if="GET_BDST === '1'">
				<el-select v-model="salary_post" class="m-2" placeholder="请选择工资发放标段" size="large" @change="syncSalary">
					<el-option
						v-for="item in salary_posts"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</div>
			<div>
				<el-date-picker
					v-model="month"
					type="month"
					placeholder="请选择考勤月份"
					format="YYYY-MM"
					value-format="YYYY-MM"
					@change="syncSalary"
				/>
			</div>
			<div v-if="button_flag">
				<el-select v-model="work_overtime" class="m-2" placeholder="请选择加班天数" size="large" clearable  @change="syncSalary">
					<el-option
						v-for="item in work_overtimes"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</div>
			<div>
				<el-select v-model="employ_type" class="m-2" placeholder="请选择用工形式" size="large" clearable  @change="syncSalary">
					<el-option
						v-for="item in employ_types"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</div>
			<div style="margin-left: 2%;width: 100vh" v-if="button_flag">
				<el-button @click="retroactiveSalaryDialog" type="primary">补发工资</el-button>
				<el-button @click="saveSalary" type="danger">保存工资表</el-button>
				<el-button @click="temporarilySaveSalary" type="warning">暂存工资表</el-button>
				<el-button @click="removeRedis" type="warning">删除暂存</el-button>
				<!--<el-button type="warning">-->
				<!--	<a style="margin-left: 2%;margin-right: 2%;text-decoration:none;color: white" :href="src">-->
				<!--		导出人员名单-->
				<!--	</a>-->
				<!--</el-button>-->

				<!--<el-button type="info" @click="changeDialogVisible = true">导入个税</el-button>-->

			</div>
			<div style="margin-left: 2%" v-if="!button_flag">
				<el-button @click="removeSalary" type="danger">删除工资表</el-button>
				<el-button type="primary">
					<a style="margin-left: 2%;margin-right: 2%;text-decoration:none;color: white" :href="src">
						导出工资表
					</a>
				</el-button>
			</div>
		</div>
		<!--生成的工资表-->
		<div style="margin-top: 1%">
			<vxe-table
				ref="vxeTable"
				border
				height="700"
				:align="allAlign"
				:column-config="{resizable: true}"
				:row-config="{isHover: true}"
				:edit-config="{trigger: 'click', mode: 'cell'}"
				:data="wage_list">
				<vxe-column type="seq" width="50" fixed="left"></vxe-column>
				<vxe-column field="realName" title="姓名" width="150" fixed="left"></vxe-column>
				<vxe-column field="kaoqin" title="出勤天数" width="80" fixed="left"></vxe-column>
				<vxe-column field="wageNum" title="岗位工资标准" width="100" fixed="left"></vxe-column>
				<vxe-column field="salaryCoefficient" title="调节系数" width="100"></vxe-column>
				<vxe-colgroup title="岗位基础工资">
					<vxe-column field="allSalary" title="基薪/岗位工资" width="130" show-overflow="tooltip" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.allSalary" type="text" placeholder="基薪/岗位工资"  @focus="allSalaryFocusEvent(row)"
							           @blur = "allSalaryBlurEvent(row)"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="companyAge" title="年功工资" width="130" show-overflow="tooltip"></vxe-column>
					<vxe-column field="titleMoney" title="技术职称津贴" width="130" show-overflow="tooltip"></vxe-column>
					<vxe-column field="ldMoney" title="流动施工津贴" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="sdMoney" title="隧道施工补贴" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="sexMoney" title="女工卫生费" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="certificateMoney" title="执业资格证书注册人员津贴" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="partTimeMoney" title="兼职津贴" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="onlyChildAllowance" title="独生子女费" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="callMoney" title="移动通信费" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="visitingAllowance" title="探亲休假交通费" width="120" show-overflow="tooltip"></vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="考核工资">
					<vxe-column field="anquanExamine" title="安全考核系数" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="zhiliangExamine" title="质量考核系数" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="huanbaoExamine" title="环保考核系数" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="monthlyAssessmentSalary" title="月度考核工资" width="120" show-overflow="tooltip"></vxe-column>
					<vxe-column field="yearAssessmentSalary" title="年度考核工资" width="120" show-overflow="tooltip"></vxe-column>
				</vxe-colgroup>
				<vxe-column field="individualRewards" title="单项奖励" width="120" show-overflow="tooltip" :edit-render="{}">
					<template #edit="{ row }">
						<vxe-input v-model="row.individualRewards" type="text" placeholder="单项奖励"  @focus="individualRewardsFocusEvent(row)"
						           @blur = "individualRewardsBlurEvent(row)"></vxe-input>
					</template>
				</vxe-column>
				<vxe-column field="workOvertime" title="加班工资" width="120" show-overflow="tooltip" :edit-render="{}">
					<template #edit="{ row }">
						<vxe-input v-model="row.workOvertime" type="text" placeholder="加班工资"  @focus="workOverFocusEvent(row)"
						@blur = "workOverBlurEvent(row)"></vxe-input>
					</template>
				</vxe-column>
				<vxe-column field="overThreeYears" title="满三年起调增工资" width="120" show-overflow="tooltip"  :edit-render="{}">
					<template #edit="{ row }">
						<vxe-input v-model="row.overThreeYears" type="text" placeholder="满三年起调增工资"  @focus="overThreeYearsFocusEvent(row)"
						           @blur = "overThreeYearsBlurEvent(row)"></vxe-input>
					</template>
				</vxe-column>
				<vxe-column field="deductionSalary" title="补发/扣减工资" width="120" show-overflow="tooltip"  :edit-render="{}">
					<template #edit="{ row }">
						<vxe-input v-model="row.deductionSalary" type="text" placeholder="满三年起调增工资"  @focus="deductionSalaryFocusEvent(row)"
						           @blur = "deductionSalaryBlurEvent(row)"></vxe-input>
					</template>
				</vxe-column>
				<vxe-column field="retirementSalary" title="扣减退休工资" width="120" show-overflow="tooltip"></vxe-column>
				<vxe-column field="yfAll" title="应发合计" width="120" show-overflow="tooltip"></vxe-column>
				<vxe-column field="endowmentInsurance" title="基本养老保险" width="120"></vxe-column>
				<vxe-column field="housingFund" title="住房公积金" width="100"></vxe-column>
				<vxe-column field="enterpriseAnnuity" title="企业年金" width="100"></vxe-column>
				<vxe-column field="medicalInsurance" title="基本医疗保险" width="120"></vxe-column>
				<vxe-column field="unemploymentInsurance" title="失业保险" width="100"></vxe-column>
				<vxe-column field="individualIncomeTax" title="个人所得税" width="120"></vxe-column>
				<vxe-column field="individualIncomeTax1" title="调整后个人所得税" width="150" :edit-render="{}">
					<template #edit="{ row }">
						<vxe-input v-model="row.individualIncomeTax1" type="text" placeholder="调整后个人所得税"  @blur="roleBlurEvent(row)"
						           @focus="roleFocusEvent(row)"></vxe-input>
					</template>
				</vxe-column>
				<!--微调去掉-->
				<!--<vxe-column field="adjustMoney" title="微调金额" width="120" :edit-render="{}">-->
				<!--	<template #edit="{ row }">-->
				<!--		<vxe-input v-model="row.adjustMoney" type="text" placeholder="微调金额" @focus="adjustFocusEvent(row)"-->
				<!--		           @blur="adjustBlurEvent(row)"></vxe-input>-->
				<!--	</template>-->
				<!--</vxe-column>-->
				<!--<vxe-column field="adjustBz" title="微调原因" width="120" :edit-render="{}">-->
				<!--	<template #edit="{ row }">-->
				<!--		<vxe-input v-model="row.adjustBz" type="text" placeholder="微调原因"></vxe-input>-->
				<!--	</template>-->
				<!--</vxe-column>-->
				<vxe-column field="sfAll" title="实发合计" width="120"></vxe-column>
				<vxe-column field="notes" title="备注" width="150" :edit-render="{}">
					<template #edit="{ row }">
						<vxe-input v-model="row.notes" type="text" placeholder="备注"></vxe-input>
					</template>
				</vxe-column>
				<!--<vxe-column title="操作" fixed="right" width="120">-->
				<!--	<template #default="{ row }">-->
				<!--		<vxe-button type="text" @click="adjustDialogVisibleConfirm(row)">删除</vxe-button>-->
				<!--	</template>-->
				<!--</vxe-column>-->
			</vxe-table>
		</div>
	<!--	补发工资弹窗-->
		<div>
			<el-dialog v-model="retroactiveDialogVisible" title="补发工资" width="80%">
				<div>
					<div style="margin-bottom: 2%">
						<el-tag>补发月份：</el-tag>
						<el-date-picker
							v-model="month1"
							type="month"
							placeholder="请选择补发考勤月份"
							format="YYYY-MM"
							value-format="YYYY-MM"
							size="small"
						/>
					</div>
					<div style="margin-bottom: 2%">
						<el-tag>加班天数：</el-tag>
						<el-select v-model="work_overtime1" class="m-2" placeholder="请选择加班天数" size="small" clearable  @change="syncSalary">
							<el-option
								v-for="item in work_overtimes"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-select>
					</div>
					<div>
						<el-tag>补发人员：</el-tag>
						<el-input placeholder="请输入补发人员姓名" clearable v-model="retroactive_user" style="width: 15%" size="small"/>
						<el-button size="small" type="danger" style="margin-left: 2%" @click="retroactiveSalary">查询工资</el-button>
					</div>
				<!--	补发工资表-->
					<div>
						<vxe-table
							border
							height="300"
							:align="allAlign"
							:column-config="{resizable: true}"
							:row-config="{isHover: true}"
							:data="wage_list_retroactive">
							<vxe-column type="seq" width="50" fixed="left"></vxe-column>
							<vxe-column field="realName" title="姓名" width="150" fixed="left"></vxe-column>
							<vxe-column field="kaoqin" title="出勤天数" width="80" fixed="left"></vxe-column>
							<vxe-column field="wageNum" title="岗位工资标准" width="100" fixed="left"></vxe-column>
							<vxe-column field="salaryCoefficient" title="调节系数" width="100"></vxe-column>
							<vxe-colgroup title="岗位基础工资">
								<vxe-column field="allSalary" title="基薪/岗位工资" width="130" show-overflow="tooltip"></vxe-column>
								<vxe-column field="companyAge" title="年功工资" width="130" show-overflow="tooltip"></vxe-column>
								<vxe-column field="titleMoney" title="技术职称津贴" width="130" show-overflow="tooltip"></vxe-column>
								<vxe-column field="ldMoney" title="流动施工津贴" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="sdMoney" title="隧道施工补贴" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="sexMoney" title="女工卫生费" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="certificateMoney" title="执业资格证书注册人员津贴" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="partTimeMoney" title="兼职津贴" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="onlyChildAllowance" title="独生子女费" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="callMoney" title="移动通信费" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="visitingAllowance" title="探亲休假交通费" width="120" show-overflow="tooltip"></vxe-column>
							</vxe-colgroup>
							<vxe-colgroup title="考核工资">
								<vxe-column field="anquanExamine" title="安全考核系数" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="zhiliangExamine" title="质量考核系数" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="huanbaoExamine" title="环保考核系数" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="monthlyAssessmentSalary" title="月度考核工资" width="120" show-overflow="tooltip"></vxe-column>
								<vxe-column field="yearAssessmentSalary" title="年度考核工资" width="120" show-overflow="tooltip"></vxe-column>
							</vxe-colgroup>
							<vxe-column field="workOvertime" title="加班工资" width="120" show-overflow="tooltip"></vxe-column>
							<vxe-column field="overThreeYears" title="满三年起调增工资" width="120" show-overflow="tooltip"></vxe-column>
							<vxe-column field="yfAll" title="应发合计" width="120" show-overflow="tooltip"></vxe-column>
							<vxe-column field="endowmentInsurance" title="基本养老保险" width="120"></vxe-column>
							<vxe-column field="housingFund" title="住房公积金" width="100"></vxe-column>
							<vxe-column field="enterpriseAnnuity" title="企业年金" width="100"></vxe-column>
							<vxe-column field="medicalInsurance" title="基本医疗保险" width="120"></vxe-column>
							<vxe-column field="unemploymentInsurance" title="失业保险" width="100"></vxe-column>
							<vxe-column field="individualIncomeTax" title="个人所得税" width="120"></vxe-column>
							<vxe-column field="individualIncomeTax1" title="调整后个人所得税" width="150"></vxe-column>
							<vxe-column field="sfAll" title="实发合计" width="120"></vxe-column>
						</vxe-table>
					</div>
				</div>
				<template #footer>
          <span class="dialog-footer">
            <el-button @click="retroactiveSalaryClose">取消</el-button>
            <el-button type="primary" @click="retroactiveSalaryBut">确认补发</el-button>
          </span>
				</template>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import {setNowMonth2} from "@/utils/time";
import {Decrypt, Decrypt1, Encrypt, Encrypt1} from "@/utils/secret";
import {ref} from "vue";
import { VXETable, VxeTableInstance } from 'vxe-table'
import { Decimal } from 'decimal.js'
export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			fileList: [],
			dataup: {
				file: '',
				userid: ''
			},
			src: '',
			wage_list: [],
			salary_post: '',
			salary_posts: [
				{
					value: '2标',
					label: '2标',
				},
				{
					value: '5标',
					label: '5标',
				}
			],
			work_overtimes: [
				{
					value: '3',
					label: '三天',
				},
				{
					value: '1',
					label: '一天',
				}
			],
			allAlign: 'center',
			month: setNowMonth2(),
			work_overtime: '',
			employ_type: '',
			employ_types: [
				{
					value: 'formal',
					label: '正式工',
				},
				{
					value: 'temporary',
					label: '合同工',
				},
				{
					value: 'labor_@lwwb@lsyg@lsyg_7000@lsyg_3500_xs@lsyg_4250_xs@lsyg_3000_xs@lsyg_2500_xs@lsyg_5000@lsyg_3500@lsgy_xsg@lsyg_15000_xs@lsyg_6500_xs',
					label: '劳务派遣',
				},
				{
					value: 'ztd_11900@ztd_8400@ztd_9400@ztd_7900@ztd_11000',
					label: '钻探队',
				}
			],
			button_flag: true,
			retroactiveDialogVisible: false,
			month1: '',
			retroactive_user: '',
			work_overtime1: '',
			wage_list_retroactive: [],
			adjust_salary: '',
			adjust_salarys: [
				{
					value: '0',
					label: '增加',
				},
				{
					value: '1',
					label: '扣除',
				}
			],
			adjustDialogVisible: false,
			adjust_money: '',
			adjust_money_bz: '',
			change_adjust: 0,
			user: [],
			changeDialogVisible: false
		}
	},
	created() {
		if (!this.$route.params.key){
			this.$router.push("/manage")
		}else {
			if (this.GET_BDST === '1'){
				this.salary_post = '2标'
			}else {
				this.salary_post = this.GET_BDST
			}
			this.syncSalary()

		}
	},
	methods: {
		imgchange(file) {
			this.fileList.push(file);
		},
		handleRemove(file) {
			for (let i = 0; i < this.fileList.length; i++) {
				if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
					this.fileList.splice(i, 1)
				}
			}
		},
		syncSalary(){
			this.button_flag = true
			if (this.GET_BDST === '1' && this.salary_post === ''){
				ElMessage.error('请先选择工资发放标段!')
			}else {
				this.axios.get('/ucUser/getUserSalary', (response) => {
					if (response.message === '工资表已上传' || response.message === '缓存取出'){
						this.wage_list = response.obj
						if (response.message === '缓存取出'){
							this.button_flag = true
						}else {
							this.button_flag = false
						}
						for (let y = 0; y < this.wage_list.length; y++){
							this.user.push(response.obj[y].realName)
							this.wage_list[y].rowIndex = y;
							this.wage_list[y].individualRewards = Decrypt1(this.wage_list[y].individualRewards)
							this.wage_list[y].wageNum = Decrypt1(this.wage_list[y].wageNum)
							this.wage_list[y].salaryCoefficient = Decrypt1(this.wage_list[y].salaryCoefficient)
							this.wage_list[y].allSalary = Decrypt1(this.wage_list[y].allSalary)
							this.wage_list[y].companyAge = Decrypt1(this.wage_list[y].companyAge)
							this.wage_list[y].titleMoney = Decrypt1(this.wage_list[y].titleMoney)
							this.wage_list[y].ldMoney = Decrypt1(this.wage_list[y].ldMoney)
							this.wage_list[y].sdMoney = Decrypt1(this.wage_list[y].sdMoney)
							this.wage_list[y].sexMoney = Decrypt1(this.wage_list[y].sexMoney)
							this.wage_list[y].certificateMoney = Decrypt1(this.wage_list[y].certificateMoney)
							this.wage_list[y].onlyChildAllowance = Decrypt1(this.wage_list[y].onlyChildAllowance)
							this.wage_list[y].callMoney = Decrypt1(this.wage_list[y].callMoney)
							this.wage_list[y].workOvertime = Decrypt1(this.wage_list[y].workOvertime)
							this.wage_list[y].yfAll = Decrypt1(this.wage_list[y].yfAll)
							this.wage_list[y].enterpriseAnnuity = Decrypt1(this.wage_list[y].enterpriseAnnuity)
							this.wage_list[y].housingFund = Decrypt1(this.wage_list[y].housingFund)
							this.wage_list[y].endowmentInsurance = Decrypt1(this.wage_list[y].endowmentInsurance)
							this.wage_list[y].unemploymentInsurance = Decrypt1(this.wage_list[y].unemploymentInsurance)
							this.wage_list[y].medicalInsurance = Decrypt1(this.wage_list[y].medicalInsurance)
							this.wage_list[y].individualIncomeTax = Decrypt1(this.wage_list[y].individualIncomeTax)
							this.wage_list[y].individualIncomeTax1 = Decrypt1(this.wage_list[y].individualIncomeTax1)
							this.wage_list[y].adjustMoney = Decrypt1(this.wage_list[y].adjustMoney)
							this.wage_list[y].sfAll = Decrypt1(this.wage_list[y].sfAll)
							this.wage_list[y].monthlyAssessmentSalary = Decrypt1(this.wage_list[y].monthlyAssessmentSalary)
							this.wage_list[y].yearAssessmentSalary = Decrypt1(this.wage_list[y].yearAssessmentSalary)
							this.wage_list[y].visitingAllowance = Decrypt1(this.wage_list[y].visitingAllowance)
							this.wage_list[y].overThreeYears = Decrypt1(this.wage_list[y].overThreeYears)
							this.wage_list[y].anquanExamine = Decrypt1(this.wage_list[y].anquanExamine)
							this.wage_list[y].zhiliangExamine = Decrypt1(this.wage_list[y].zhiliangExamine)
							this.wage_list[y].huanbaoExamine = Decrypt1(this.wage_list[y].huanbaoExamine)
							this.wage_list[y].partTimeMoney = Decrypt1(this.wage_list[y].partTimeMoney)
							this.wage_list[y].deductionSalary = Decrypt1(this.wage_list[y].deductionSalary)
							this.wage_list[y].retirementSalary = Decrypt1(this.wage_list[y].retirementSalary)
						}
						// this.wage_list = response.obj
						//this.src = 'http://localhost:8082/wageFinal/leadWageFile?bdst=' + this.GET_BDST + '&salary_post=' + this.salary_post
						 //+ '&wage_month=' + this.month
						this.src = 'https://show.cr12cz.cn/czsczq/wageFinal/leadWageFile?bdst=' + this.GET_BDST + '&salary_post=' + this.salary_post
							+ '&wage_month=' + this.month
					}else {
						this.button_flag = true
						this.wage_list = response.obj
						for (let i = 0; i < this.wage_list.length; i++){
							this.user.push(response.obj[i].realName)
							this.wage_list[i].rowIndex = i;
							this.wage_list[i].individualRewards = Decrypt(this.wage_list[i].individualRewards)
							this.wage_list[i].wageNum = Decrypt(this.wage_list[i].wageNum)
							this.wage_list[i].salaryCoefficient = Decrypt(this.wage_list[i].salaryCoefficient)
							this.wage_list[i].allSalary = Decrypt(this.wage_list[i].allSalary)
							this.wage_list[i].companyAge = Decrypt(this.wage_list[i].companyAge)
							this.wage_list[i].titleMoney = Decrypt(this.wage_list[i].titleMoney)
							this.wage_list[i].ldMoney = Decrypt(this.wage_list[i].ldMoney)
							this.wage_list[i].sdMoney = Decrypt(this.wage_list[i].sdMoney)
							this.wage_list[i].sexMoney = Decrypt(this.wage_list[i].sexMoney)
							this.wage_list[i].certificateMoney = Decrypt(this.wage_list[i].certificateMoney)
							this.wage_list[i].onlyChildAllowance = Decrypt(this.wage_list[i].onlyChildAllowance)
							this.wage_list[i].callMoney = Decrypt(this.wage_list[i].callMoney)
							this.wage_list[i].workOvertime = Decrypt(this.wage_list[i].workOvertime)
							this.wage_list[i].yfAll = Decrypt(this.wage_list[i].yfAll)
							this.wage_list[i].enterpriseAnnuity = Decrypt(this.wage_list[i].enterpriseAnnuity)
							this.wage_list[i].housingFund = Decrypt(this.wage_list[i].housingFund)
							this.wage_list[i].endowmentInsurance = Decrypt(this.wage_list[i].endowmentInsurance)
							this.wage_list[i].unemploymentInsurance = Decrypt(this.wage_list[i].unemploymentInsurance)
							this.wage_list[i].medicalInsurance = Decrypt(this.wage_list[i].medicalInsurance)
							this.wage_list[i].individualIncomeTax = Decrypt(this.wage_list[i].individualIncomeTax)
							this.wage_list[i].individualIncomeTax1 = Decrypt(this.wage_list[i].individualIncomeTax1)
							this.wage_list[i].adjustMoney = Decrypt(this.wage_list[i].adjustMoney)
							this.wage_list[i].sfAll = Decrypt(this.wage_list[i].sfAll)
							this.wage_list[i].monthlyAssessmentSalary = Decrypt(this.wage_list[i].monthlyAssessmentSalary)
							this.wage_list[i].yearAssessmentSalary = Decrypt(this.wage_list[i].yearAssessmentSalary)
							this.wage_list[i].visitingAllowance = Decrypt(this.wage_list[i].visitingAllowance)
							this.wage_list[i].overThreeYears = Decrypt(this.wage_list[i].overThreeYears)
							this.wage_list[i].anquanExamine = Decrypt(this.wage_list[i].anquanExamine)
							this.wage_list[i].zhiliangExamine = Decrypt(this.wage_list[i].zhiliangExamine)
							this.wage_list[i].huanbaoExamine = Decrypt(this.wage_list[i].huanbaoExamine)
							this.wage_list[i].partTimeMoney = Decrypt(this.wage_list[i].partTimeMoney)
							this.wage_list[i].deductionSalary = Decrypt(this.wage_list[i].deductionSalary)
							this.wage_list[i].retirementSalary = Decrypt(this.wage_list[i].retirementSalary)
							//this.src = 'http://localhost:8082/ucUser/leadOutUser?wage_user=' + this.user
							//this.src = 'https://www.gjegm.ltd/czsczq/ucUser/leadOutUser?wage_user=' + this.user
						}
					}

				},{
					bdst: this.GET_BDST,
					salary_post: this.salary_post,
					month: this.month,
					work_overtime: this.work_overtime,
					employ_type: this.employ_type
				})
			}
		},
		roleBlurEvent(row){
			row.sfAll = new Decimal(row.yfAll).sub(new Decimal(row.medicalInsurance)).sub(new Decimal(row.unemploymentInsurance))
				.sub(new Decimal(row.endowmentInsurance)).sub(new Decimal(row.housingFund)).sub(new Decimal(row.enterpriseAnnuity))
				.sub(new Decimal(row.individualIncomeTax1))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).add(new Decimal(row.individualIncomeTax1))
			//this.wage_list[this.wage_list.length - 1].yfAll = this.wage_list[this.wage_list.length - 1].yfAll * 1 + row.individualIncomeTax1 * 1
			this.wage_list[this.wage_list.length - 1].individualIncomeTax1 = new Decimal(this.wage_list[this.wage_list.length - 1].individualIncomeTax1).add(new Decimal(row.individualIncomeTax1))
		},
		roleFocusEvent(row){
			this.wage_list[this.wage_list.length - 1].individualIncomeTax1 = new Decimal(this.wage_list[this.wage_list.length - 1].individualIncomeTax1).sub(new Decimal(row.individualIncomeTax1))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).sub(new Decimal(row.individualIncomeTax1))
			//this.wage_list[this.wage_list.length - 1].yfAll = this.wage_list[this.wage_list.length - 1].yfAll * 1 - row.individualIncomeTax1 * 1
		},

		workOverFocusEvent(row){
			row.yfAll = new Decimal(row.yfAll).sub(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].workOvertime = new Decimal(this.wage_list[this.wage_list.length - 1].workOvertime).sub(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).sub(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).sub(new Decimal(row.workOvertime))
		},
		allSalaryBlurEvent(row){
			row.yfAll = new Decimal(row.yfAll).add(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].workOvertime = new Decimal(this.wage_list[this.wage_list.length - 1].workOvertime).add(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).add(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).add(new Decimal(row.workOvertime))
		},
		allSalaryFocusEvent(row){
			row.yfAll = new Decimal(row.yfAll).sub(new Decimal(row.allSalary))
			this.wage_list[this.wage_list.length - 1].allSalary = new Decimal(this.wage_list[this.wage_list.length - 1].allSalary).sub(new Decimal(row.allSalary))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).sub(new Decimal(row.allSalary))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).sub(new Decimal(row.allSalary))
		},
		workOverBlurEvent(row){
			row.yfAll = new Decimal(row.yfAll).add(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].workOvertime = new Decimal(this.wage_list[this.wage_list.length - 1].workOvertime).add(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).add(new Decimal(row.workOvertime))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).add(new Decimal(row.workOvertime))
		},
		overThreeYearsFocusEvent(row){
			row.yfAll = new Decimal(row.yfAll).sub(new Decimal(row.overThreeYears))
			this.wage_list[this.wage_list.length - 1].overThreeYears = new Decimal(this.wage_list[this.wage_list.length - 1].overThreeYears).sub(new Decimal(row.overThreeYears))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).sub(new Decimal(row.overThreeYears))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).sub(new Decimal(row.overThreeYears))
		},
		overThreeYearsBlurEvent(row){
			row.yfAll = new Decimal(row.yfAll).add(new Decimal(row.overThreeYears))
			this.wage_list[this.wage_list.length - 1].overThreeYears = new Decimal(this.wage_list[this.wage_list.length - 1].overThreeYears).add(new Decimal(row.overThreeYears))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).add(new Decimal(row.overThreeYears))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).add(new Decimal(row.overThreeYears))
		},

		deductionSalaryFocusEvent(row){
			row.yfAll = new Decimal(row.yfAll).sub(new Decimal(row.deductionSalary))
			this.wage_list[this.wage_list.length - 1].deductionSalary = new Decimal(this.wage_list[this.wage_list.length - 1].deductionSalary).sub(new Decimal(row.deductionSalary))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).sub(new Decimal(row.deductionSalary))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).sub(new Decimal(row.deductionSalary))
		},
		deductionSalaryBlurEvent(row){
			row.yfAll = new Decimal(row.yfAll).add(new Decimal(row.deductionSalary))
			this.wage_list[this.wage_list.length - 1].deductionSalary = new Decimal(this.wage_list[this.wage_list.length - 1].deductionSalary).add(new Decimal(row.deductionSalary))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).add(new Decimal(row.deductionSalary))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).add(new Decimal(row.deductionSalary))
		},


		individualRewardsFocusEvent(row){
			row.yfAll = new Decimal(row.yfAll).sub(new Decimal(row.individualRewards))
			this.wage_list[this.wage_list.length - 1].individualRewards = new Decimal(this.wage_list[this.wage_list.length - 1].individualRewards).sub(new Decimal(row.individualRewards))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).sub(new Decimal(row.individualRewards))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).sub(new Decimal(row.individualRewards))

		},
		individualRewardsBlurEvent(row){
			row.yfAll = new Decimal(row.yfAll).add(new Decimal(row.individualRewards))
			this.wage_list[this.wage_list.length - 1].individualRewards = new Decimal(this.wage_list[this.wage_list.length - 1].individualRewards).add(new Decimal(row.individualRewards))
			this.wage_list[this.wage_list.length - 1].sfAll = new Decimal(this.wage_list[this.wage_list.length - 1].sfAll).add(new Decimal(row.individualRewards))
			this.wage_list[this.wage_list.length - 1].yfAll = new Decimal(this.wage_list[this.wage_list.length - 1].yfAll).add(new Decimal(row.individualRewards))
		},
		saveSalary(){
			for (let i = 0; i < this.wage_list.length; i++){
				this.wage_list[i].wageNum = Encrypt1(this.wage_list[i].wageNum)
				this.wage_list[i].individualRewards = Encrypt1(this.wage_list[i].individualRewards)
				this.wage_list[i].salaryCoefficient = Encrypt1(this.wage_list[i].salaryCoefficient)
				this.wage_list[i].allSalary = Encrypt1(this.wage_list[i].allSalary)
				this.wage_list[i].companyAge = Encrypt1(this.wage_list[i].companyAge)
				this.wage_list[i].titleMoney = Encrypt1(this.wage_list[i].titleMoney)
				this.wage_list[i].ldMoney = Encrypt1(this.wage_list[i].ldMoney)
				this.wage_list[i].sdMoney = Encrypt1(this.wage_list[i].sdMoney)
				this.wage_list[i].sexMoney = Encrypt1(this.wage_list[i].sexMoney)
				this.wage_list[i].certificateMoney = Encrypt1(this.wage_list[i].certificateMoney)
				this.wage_list[i].onlyChildAllowance = Encrypt1(this.wage_list[i].onlyChildAllowance)
				this.wage_list[i].callMoney = Encrypt1(this.wage_list[i].callMoney)
				this.wage_list[i].workOvertime = Encrypt1(this.wage_list[i].workOvertime)
				this.wage_list[i].yfAll = Encrypt1(this.wage_list[i].yfAll)
				this.wage_list[i].enterpriseAnnuity = Encrypt1(this.wage_list[i].enterpriseAnnuity)
				this.wage_list[i].housingFund = Encrypt1(this.wage_list[i].housingFund)
				this.wage_list[i].endowmentInsurance = Encrypt1(this.wage_list[i].endowmentInsurance)
				this.wage_list[i].unemploymentInsurance = Encrypt1(this.wage_list[i].unemploymentInsurance)
				this.wage_list[i].medicalInsurance = Encrypt1(this.wage_list[i].medicalInsurance)
				this.wage_list[i].individualIncomeTax = Encrypt1(this.wage_list[i].individualIncomeTax)
				this.wage_list[i].individualIncomeTax1 = Encrypt1(this.wage_list[i].individualIncomeTax1)
				this.wage_list[i].adjustMoney = Encrypt1(this.wage_list[i].adjustMoney)
				this.wage_list[i].sfAll = Encrypt1(this.wage_list[i].sfAll)
				this.wage_list[i].monthlyAssessmentSalary = Encrypt1(this.wage_list[i].monthlyAssessmentSalary)
				this.wage_list[i].yearAssessmentSalary = Encrypt1(this.wage_list[i].yearAssessmentSalary)
				this.wage_list[i].visitingAllowance = Encrypt1(this.wage_list[i].visitingAllowance)
				this.wage_list[i].overThreeYears = Encrypt1(this.wage_list[i].overThreeYears)
				this.wage_list[i].partTimeMoney = Encrypt1(this.wage_list[i].partTimeMoney)
				this.wage_list[i].anquanExamine = Encrypt1(this.wage_list[i].anquanExamine)
				this.wage_list[i].zhiliangExamine = Encrypt1(this.wage_list[i].zhiliangExamine)
				this.wage_list[i].huanbaoExamine = Encrypt1(this.wage_list[i].huanbaoExamine)
				this.wage_list[i].deductionSalary = Encrypt1(this.wage_list[i].deductionSalary)
				this.wage_list[i].retirementSalary = Encrypt1(this.wage_list[i].retirementSalary)
			}
			this.axios.post('/wageFinal/saveWageFinal', (response) => {
				if (response.obj){
					this.button_flag = false
					for (let i = 0; i < this.wage_list.length; i++){
						this.wage_list[i].wageNum = Decrypt1(this.wage_list[i].wageNum)
						this.wage_list[i].individualRewards = Decrypt1(this.wage_list[i].individualRewards)
						this.wage_list[i].salaryCoefficient = Decrypt1(this.wage_list[i].salaryCoefficient)
						this.wage_list[i].allSalary = Decrypt1(this.wage_list[i].allSalary)
						this.wage_list[i].companyAge = Decrypt1(this.wage_list[i].companyAge)
						this.wage_list[i].titleMoney = Decrypt1(this.wage_list[i].titleMoney)
						this.wage_list[i].ldMoney = Decrypt1(this.wage_list[i].ldMoney)
						this.wage_list[i].sdMoney = Decrypt1(this.wage_list[i].sdMoney)
						this.wage_list[i].sexMoney = Decrypt1(this.wage_list[i].sexMoney)
						this.wage_list[i].certificateMoney = Decrypt1(this.wage_list[i].certificateMoney)
						this.wage_list[i].onlyChildAllowance = Decrypt1(this.wage_list[i].onlyChildAllowance)
						this.wage_list[i].callMoney = Decrypt1(this.wage_list[i].callMoney)
						this.wage_list[i].workOvertime = Decrypt1(this.wage_list[i].workOvertime)
						this.wage_list[i].yfAll = Decrypt1(this.wage_list[i].yfAll)
						this.wage_list[i].enterpriseAnnuity = Decrypt1(this.wage_list[i].enterpriseAnnuity)
						this.wage_list[i].housingFund = Decrypt1(this.wage_list[i].housingFund)
						this.wage_list[i].endowmentInsurance = Decrypt1(this.wage_list[i].endowmentInsurance)
						this.wage_list[i].unemploymentInsurance = Decrypt1(this.wage_list[i].unemploymentInsurance)
						this.wage_list[i].medicalInsurance = Decrypt1(this.wage_list[i].medicalInsurance)
						this.wage_list[i].individualIncomeTax = Decrypt1(this.wage_list[i].individualIncomeTax)
						this.wage_list[i].individualIncomeTax1 = Decrypt1(this.wage_list[i].individualIncomeTax1)
						this.wage_list[i].adjustMoney = Decrypt1(this.wage_list[i].adjustMoney)
						this.wage_list[i].sfAll = Decrypt1(this.wage_list[i].sfAll)
						this.wage_list[i].monthlyAssessmentSalary = Decrypt1(this.wage_list[i].monthlyAssessmentSalary)
						this.wage_list[i].yearAssessmentSalary = Decrypt1(this.wage_list[i].yearAssessmentSalary)
						this.wage_list[i].visitingAllowance = Decrypt1(this.wage_list[i].visitingAllowance)
						this.wage_list[i].overThreeYears = Decrypt1(this.wage_list[i].overThreeYears)
						this.wage_list[i].partTimeMoney = Decrypt1(this.wage_list[i].partTimeMoney)
						this.wage_list[i].anquanExamine = Decrypt1(this.wage_list[i].anquanExamine)
						this.wage_list[i].zhiliangExamine = Decrypt1(this.wage_list[i].zhiliangExamine)
						this.wage_list[i].huanbaoExamine = Decrypt1(this.wage_list[i].huanbaoExamine)
						this.wage_list[i].deductionSalary = Decrypt1(this.wage_list[i].deductionSalary)
						this.wage_list[i].retirementSalary = Decrypt1(this.wage_list[i].retirementSalary)
					}
				}
			},{
				wage_finals: JSON.stringify(this.wage_list),
				wage_month: this.month,
				salary_post: this.salary_post,
				bdst: this.GET_BDST,
				type: this.employ_type
			})
		},
		retroactiveSalaryDialog(){
			if (this.GET_BDST === '1' && this.salary_post === ''){
				ElMessage.error('请先选择工资发放标段!')
			}else {
				this.retroactiveDialogVisible = true
			}
		},
		retroactiveSalaryClose(){
			this.month1 = ''
			this.work_overtime1 = ''
			this.retroactive_user = ''
			this.wage_list_retroactive = []
			this.retroactiveDialogVisible = false
		},
		retroactiveSalary(){
			this.axios.get('/ucUser/retroactiveSalary', (response) => {
				this.wage_list_retroactive = response.obj
				console.log(this.wage_list_retroactive)
				for (let i = 0; i < this.wage_list_retroactive.length; i++){
					this.wage_list_retroactive[i].wageNum = Decrypt(this.wage_list_retroactive[i].wageNum)
					this.wage_list[i].individualRewards = Decrypt(this.wage_list[i].individualRewards)
					this.wage_list_retroactive[i].salaryCoefficient = Decrypt(this.wage_list_retroactive[i].salaryCoefficient)
					this.wage_list_retroactive[i].allSalary = Decrypt(this.wage_list_retroactive[i].allSalary)
					this.wage_list_retroactive[i].companyAge = Decrypt(this.wage_list_retroactive[i].companyAge)
					this.wage_list_retroactive[i].titleMoney = Decrypt(this.wage_list_retroactive[i].titleMoney)
					this.wage_list_retroactive[i].ldMoney = Decrypt(this.wage_list_retroactive[i].ldMoney)
					this.wage_list_retroactive[i].sdMoney = Decrypt(this.wage_list_retroactive[i].sdMoney)
					this.wage_list_retroactive[i].sexMoney = Decrypt(this.wage_list_retroactive[i].sexMoney)
					this.wage_list_retroactive[i].certificateMoney = Decrypt(this.wage_list_retroactive[i].certificateMoney)
					this.wage_list_retroactive[i].onlyChildAllowance = Decrypt(this.wage_list_retroactive[i].onlyChildAllowance)
					this.wage_list_retroactive[i].callMoney = Decrypt(this.wage_list_retroactive[i].callMoney)
					this.wage_list_retroactive[i].workOvertime = Decrypt(this.wage_list_retroactive[i].workOvertime)
					this.wage_list_retroactive[i].yfAll = Decrypt(this.wage_list_retroactive[i].yfAll)
					this.wage_list_retroactive[i].enterpriseAnnuity = Decrypt(this.wage_list_retroactive[i].enterpriseAnnuity)
					this.wage_list_retroactive[i].housingFund = Decrypt(this.wage_list_retroactive[i].housingFund)
					this.wage_list_retroactive[i].endowmentInsurance = Decrypt(this.wage_list_retroactive[i].endowmentInsurance)
					this.wage_list_retroactive[i].unemploymentInsurance = Decrypt(this.wage_list_retroactive[i].unemploymentInsurance)
					this.wage_list_retroactive[i].medicalInsurance = Decrypt(this.wage_list_retroactive[i].medicalInsurance)
					this.wage_list_retroactive[i].individualIncomeTax = Decrypt(this.wage_list_retroactive[i].individualIncomeTax)
					this.wage_list_retroactive[i].individualIncomeTax1 = Decrypt(this.wage_list_retroactive[i].individualIncomeTax1)
					this.wage_list_retroactive[i].sfAll = Decrypt(this.wage_list_retroactive[i].sfAll)
					this.wage_list[i].monthlyAssessmentSalary = Decrypt(this.wage_list[i].monthlyAssessmentSalary)
					this.wage_list[i].yearAssessmentSalary = Decrypt(this.wage_list[i].yearAssessmentSalary)
					this.wage_list[i].visitingAllowance = Decrypt(this.wage_list[i].visitingAllowance)
					this.wage_list[i].overThreeYears = Decrypt(this.wage_list[i].overThreeYears)
					this.wage_list[i].partTimeMoney = Decrypt(this.wage_list[i].partTimeMoney)
					this.wage_list[i].anquanExamine = Decrypt(this.wage_list[i].anquanExamine)
					this.wage_list[i].zhiliangExamine = Decrypt(this.wage_list[i].zhiliangExamine)
					this.wage_list[i].huanbaoExamine = Decrypt(this.wage_list[i].huanbaoExamine)
					this.wage_list[i].deductionSalary = Decrypt(this.wage_list[i].deductionSalary)
					this.wage_list[i].retirementSalary = Decrypt(this.wage_list[i].retirementSalary)
				}
			},{
				month: this.month1,
				name: this.retroactive_user,
				work_overtime: this.work_overtime1,
				salary_post: this.salary_post,
				bdst: this.GET_BDST
			})
		},
		retroactiveSalaryBut(){
			this.wage_list.push(this.wage_list_retroactive[0])
			this.wage_list[this.wage_list.length - 1].rowIndex = this.wage_list.length - 1;
			this.$refs.vxeTable.loadData(this.wage_list)
			this.retroactiveDialogVisible = false
			ElMessage.success('添加成功!')
			console.log(this.wage_list)
		},
		adjustBlurEvent(row){
			row.sfAll = row.sfAll * 1 + row.adjustMoney * 1
		},
		adjustFocusEvent(row){
			row.sfAll = row.sfAll * 1 - row.adjustMoney * 1
		},
		adjustDialogVisibleConfirm(row){
			this.wage_list.splice(row.rowIndex, 1);
			this.$refs.vxeTable.loadData(this.wage_list)
			ElMessage.success('删除成功!')
			for (let i = 0; i < this.wage_list.length; i++) {
				this.wage_list[i].rowIndex = i;
			}
		},
		leadin(){
			this.dataup.file = this.fileList[0].raw;
			this.axios.post('/ucUser/upChangeSalary', (response) => {
				if (response.code === 200){
					this.wage_list = response.obj
					for (let i = 0; i < this.wage_list.length; i++){
						this.wage_list[i].rowIndex = i;
						this.wage_list[i].wageNum = Decrypt(this.wage_list[i].wageNum)
						this.wage_list[i].individualRewards = Decrypt(this.wage_list[i].individualRewards)
						this.wage_list[i].salaryCoefficient = Decrypt(this.wage_list[i].salaryCoefficient)
						this.wage_list[i].allSalary = Decrypt(this.wage_list[i].allSalary)
						this.wage_list[i].companyAge = Decrypt(this.wage_list[i].companyAge)
						this.wage_list[i].titleMoney = Decrypt(this.wage_list[i].titleMoney)
						this.wage_list[i].ldMoney = Decrypt(this.wage_list[i].ldMoney)
						this.wage_list[i].sdMoney = Decrypt(this.wage_list[i].sdMoney)
						this.wage_list[i].sexMoney = Decrypt(this.wage_list[i].sexMoney)
						this.wage_list[i].certificateMoney = Decrypt(this.wage_list[i].certificateMoney)
						this.wage_list[i].onlyChildAllowance = Decrypt(this.wage_list[i].onlyChildAllowance)
						this.wage_list[i].callMoney = Decrypt(this.wage_list[i].callMoney)
						this.wage_list[i].workOvertime = Decrypt(this.wage_list[i].workOvertime)
						this.wage_list[i].yfAll = Decrypt(this.wage_list[i].yfAll)
						this.wage_list[i].enterpriseAnnuity = Decrypt(this.wage_list[i].enterpriseAnnuity)
						this.wage_list[i].housingFund = Decrypt(this.wage_list[i].housingFund)
						this.wage_list[i].endowmentInsurance = Decrypt(this.wage_list[i].endowmentInsurance)
						this.wage_list[i].unemploymentInsurance = Decrypt(this.wage_list[i].unemploymentInsurance)
						this.wage_list[i].medicalInsurance = Decrypt(this.wage_list[i].medicalInsurance)
						this.wage_list[i].individualIncomeTax = Decrypt(this.wage_list[i].individualIncomeTax)
						this.wage_list[i].individualIncomeTax1 = Decrypt(this.wage_list[i].individualIncomeTax1)
						this.wage_list[i].adjustMoney = Decrypt(this.wage_list[i].adjustMoney)
						this.wage_list[i].sfAll = Decrypt(this.wage_list[i].sfAll)
						this.wage_list[i].monthlyAssessmentSalary = Decrypt(this.wage_list[i].monthlyAssessmentSalary)
						this.wage_list[i].yearAssessmentSalary = Decrypt(this.wage_list[i].yearAssessmentSalary)
						this.wage_list[i].visitingAllowance = Decrypt(this.wage_list[i].visitingAllowance)
						this.wage_list[i].overThreeYears = Decrypt(this.wage_list[i].overThreeYears)
						this.wage_list[i].partTimeMoney = Decrypt(this.wage_list[i].partTimeMoney)
						this.wage_list[i].anquanExamine = Decrypt(this.wage_list[i].anquanExamine)
						this.wage_list[i].zhiliangExamine = Decrypt(this.wage_list[i].zhiliangExamine)
						this.wage_list[i].huanbaoExamine = Decrypt(this.wage_list[i].huanbaoExamine)
						this.wage_list[i].deductionSalary = Decrypt(this.wage_list[i].deductionSalary)
						this.wage_list[i].retirementSalary = Decrypt(this.wage_list[i].retirementSalary)
					}
					this.changeDialogVisible = false
					this.dataup.file = ''
					this.fileList = []
				}else {
					ElMessage.error('上传失败,请联系科信部!')
				}

				console.log(this.wage_list)
			},{
				bdst: this.GET_BDST,
				salary_post: this.salary_post,
				month: this.month,
				work_overtime: this.work_overtime,
				employ_type: this.employ_type,
				file: this.dataup.file
			})
		},
		changeGsClose(){
			this.changeDialogVisible = false
			this.dataup.file = ''
			this.fileList = []
		},
		removeSalary(){
			this.axios.post('/wageFinal/removeSalary', (response) => {
				if (response.obj){
					ElMessage.success('删除成功!')
					this.reload()
				}
			},{
				bdst: this.GET_BDST,
				salary_post: this.salary_post,
				wage_month: this.month,
				employ_type: this.employ_type
			})
		},
		temporarilySaveSalary(){
			this.button_flag = true
			for (let i = 0; i < this.wage_list.length; i++){
				this.wage_list[i].wageNum = Encrypt1(this.wage_list[i].wageNum)
				this.wage_list[i].individualRewards = Encrypt1(this.wage_list[i].individualRewards)
				this.wage_list[i].salaryCoefficient = Encrypt1(this.wage_list[i].salaryCoefficient)
				this.wage_list[i].allSalary = Encrypt1(this.wage_list[i].allSalary)
				this.wage_list[i].companyAge = Encrypt1(this.wage_list[i].companyAge)
				this.wage_list[i].titleMoney = Encrypt1(this.wage_list[i].titleMoney)
				this.wage_list[i].ldMoney = Encrypt1(this.wage_list[i].ldMoney)
				this.wage_list[i].sdMoney = Encrypt1(this.wage_list[i].sdMoney)
				this.wage_list[i].sexMoney = Encrypt1(this.wage_list[i].sexMoney)
				this.wage_list[i].certificateMoney = Encrypt1(this.wage_list[i].certificateMoney)
				this.wage_list[i].onlyChildAllowance = Encrypt1(this.wage_list[i].onlyChildAllowance)
				this.wage_list[i].callMoney = Encrypt1(this.wage_list[i].callMoney)
				this.wage_list[i].workOvertime = Encrypt1(this.wage_list[i].workOvertime)
				this.wage_list[i].yfAll = Encrypt1(this.wage_list[i].yfAll)
				this.wage_list[i].enterpriseAnnuity = Encrypt1(this.wage_list[i].enterpriseAnnuity)
				this.wage_list[i].housingFund = Encrypt1(this.wage_list[i].housingFund)
				this.wage_list[i].endowmentInsurance = Encrypt1(this.wage_list[i].endowmentInsurance)
				this.wage_list[i].unemploymentInsurance = Encrypt1(this.wage_list[i].unemploymentInsurance)
				this.wage_list[i].medicalInsurance = Encrypt1(this.wage_list[i].medicalInsurance)
				this.wage_list[i].individualIncomeTax = Encrypt1(this.wage_list[i].individualIncomeTax)
				this.wage_list[i].individualIncomeTax1 = Encrypt1(this.wage_list[i].individualIncomeTax1)
				this.wage_list[i].adjustMoney = Encrypt1(this.wage_list[i].adjustMoney)
				this.wage_list[i].sfAll = Encrypt1(this.wage_list[i].sfAll)
				this.wage_list[i].monthlyAssessmentSalary = Encrypt1(this.wage_list[i].monthlyAssessmentSalary)
				this.wage_list[i].yearAssessmentSalary = Encrypt1(this.wage_list[i].yearAssessmentSalary)
				this.wage_list[i].visitingAllowance = Encrypt1(this.wage_list[i].visitingAllowance)
				this.wage_list[i].overThreeYears = Encrypt1(this.wage_list[i].overThreeYears)
				this.wage_list[i].partTimeMoney = Encrypt1(this.wage_list[i].partTimeMoney)
				this.wage_list[i].anquanExamine = Encrypt1(this.wage_list[i].anquanExamine)
				this.wage_list[i].zhiliangExamine = Encrypt1(this.wage_list[i].zhiliangExamine)
				this.wage_list[i].huanbaoExamine = Encrypt1(this.wage_list[i].huanbaoExamine)
				this.wage_list[i].deductionSalary = Encrypt1(this.wage_list[i].deductionSalary)
				this.wage_list[i].retirementSalary = Encrypt1(this.wage_list[i].retirementSalary)
			}
			//this.wage_list[this.wage_list.length - 1].enterpriseAnnuity = Encrypt1('8397')
			//this.wage_list[this.wage_list.length - 1].monthlyAssessmentSalary = Encrypt1('619692.67')
			this.axios.post('/ucUser/addRedis', (response) => {
				if (response.message === '请求成功'){
					ElMessage.success(response.obj)

					for (let i = 0; i < this.wage_list.length; i++){
						this.wage_list[i].wageNum = Decrypt1(this.wage_list[i].wageNum)
						this.wage_list[i].individualRewards = Decrypt1(this.wage_list[i].individualRewards)
						this.wage_list[i].salaryCoefficient = Decrypt1(this.wage_list[i].salaryCoefficient)
						this.wage_list[i].allSalary = Decrypt1(this.wage_list[i].allSalary)
						this.wage_list[i].companyAge = Decrypt1(this.wage_list[i].companyAge)
						this.wage_list[i].titleMoney = Decrypt1(this.wage_list[i].titleMoney)
						this.wage_list[i].ldMoney = Decrypt1(this.wage_list[i].ldMoney)
						this.wage_list[i].sdMoney = Decrypt1(this.wage_list[i].sdMoney)
						this.wage_list[i].sexMoney = Decrypt1(this.wage_list[i].sexMoney)
						this.wage_list[i].certificateMoney = Decrypt1(this.wage_list[i].certificateMoney)
						this.wage_list[i].onlyChildAllowance = Decrypt1(this.wage_list[i].onlyChildAllowance)
						this.wage_list[i].callMoney = Decrypt1(this.wage_list[i].callMoney)
						this.wage_list[i].workOvertime = Decrypt1(this.wage_list[i].workOvertime)
						this.wage_list[i].yfAll = Decrypt1(this.wage_list[i].yfAll)
						this.wage_list[i].enterpriseAnnuity = Decrypt1(this.wage_list[i].enterpriseAnnuity)
						this.wage_list[i].housingFund = Decrypt1(this.wage_list[i].housingFund)
						this.wage_list[i].endowmentInsurance = Decrypt1(this.wage_list[i].endowmentInsurance)
						this.wage_list[i].unemploymentInsurance = Decrypt1(this.wage_list[i].unemploymentInsurance)
						this.wage_list[i].medicalInsurance = Decrypt1(this.wage_list[i].medicalInsurance)
						this.wage_list[i].individualIncomeTax = Decrypt1(this.wage_list[i].individualIncomeTax)
						this.wage_list[i].individualIncomeTax1 = Decrypt1(this.wage_list[i].individualIncomeTax1)
						this.wage_list[i].adjustMoney = Decrypt1(this.wage_list[i].adjustMoney)
						this.wage_list[i].sfAll = Decrypt1(this.wage_list[i].sfAll)
						this.wage_list[i].monthlyAssessmentSalary = Decrypt1(this.wage_list[i].monthlyAssessmentSalary)
						this.wage_list[i].yearAssessmentSalary = Decrypt1(this.wage_list[i].yearAssessmentSalary)
						this.wage_list[i].visitingAllowance = Decrypt1(this.wage_list[i].visitingAllowance)
						this.wage_list[i].overThreeYears = Decrypt1(this.wage_list[i].overThreeYears)
						this.wage_list[i].partTimeMoney = Decrypt1(this.wage_list[i].partTimeMoney)
						this.wage_list[i].anquanExamine = Decrypt1(this.wage_list[i].anquanExamine)
						this.wage_list[i].zhiliangExamine = Decrypt1(this.wage_list[i].zhiliangExamine)
						this.wage_list[i].huanbaoExamine = Decrypt1(this.wage_list[i].huanbaoExamine)
						this.wage_list[i].deductionSalary = Decrypt1(this.wage_list[i].deductionSalary)
						this.wage_list[i].retirementSalary = Decrypt1(this.wage_list[i].retirementSalary)
					}
				}
			},{
				wage_finals: JSON.stringify(this.wage_list),
				wage_month: this.month,
				salary_post: this.salary_post,
				bdst: this.GET_BDST,
				type: this.employ_type
			})
		},
		removeRedis(){
			this.axios.get('/ucUser/removeRedis', (response) => {
				if (response.obj){
					ElMessage.success('暂存删除成功!')
					this.reload()
				}
			},{
				wage_month: this.month,
				salary_post: this.salary_post,
				bdst: this.GET_BDST
			})
		},
		leadoutSalary(){

		}
	}
}
</script>

<style>

</style>