<template>
	<div style="margin-left: 2%">
		<el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
			<el-tab-pane label="社保管理" name="b"><Insurance_index v-if="b" /></el-tab-pane>
			<el-tab-pane label="执业资格证书管理" name="c"><Certificate_index v-if="c" /></el-tab-pane>
			<el-tab-pane label="兼职管理" name="d"><Part_time_job v-if="d" /></el-tab-pane>
			<el-tab-pane label="考核系数管理" name="e"><Wage_examine v-if="e" /></el-tab-pane>
			<el-tab-pane label="探亲休假交通费管理" name="f"><Wage_visiting v-if="f" /></el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Insurance_index from "@/views/salary_post/new_post/sync_salary/insurance_index.vue";
import Certificate_index from "@/views/salary_post/new_post/sync_salary/certificate_index.vue";
import Part_time_job from "@/views/salary_post/new_post/sync_salary/part_time_job.vue";
import Wage_visiting from "@/views/salary_post/new_post/sync_salary/wage_visiting.vue";
import Wage_examine from "@/views/salary_post/new_post/sync_salary/wage_examine.vue";
import Sync_salary_index from "@/views/salary_post/new_post/sync_salary/sync_salary_index.vue";
import Header from "@/views/header.vue";

export default {
	name: "",
	components: {Insurance_index, Certificate_index, Part_time_job, Wage_visiting, Wage_examine},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'b',
			b: true,
			c: false,
			d: false,
			e: false,
			f: false
		}
	},
	created() {
	},
	methods: {
		handleClick(tab, event){
			if (tab.props.name === 'b') {
				this.b = true
				this.c = false
				this.d = false
				this.e = false
				this.f = false
			}
			if (tab.props.name === 'c') {
				this.b = false
				this.c = true
				this.d = false
				this.e = false
				this.f = false
			}
			if (tab.props.name === 'd') {
				this.b = false
				this.c = false
				this.d = true
				this.e = false
				this.f = false
			}
			if (tab.props.name === 'e') {
				this.b = false
				this.c = false
				this.e = true
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'f') {
				this.b = false
				this.c = false
				this.f = true
				this.e = false
				this.d = false
			}
		}
	}
}
</script>

<style>

</style>