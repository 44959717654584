<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">财务管理</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>

		<div id="forms_div2">
			<el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
				<el-tab-pane label="生成工资表" name="a"><Sync_salary_index v-if="a" /></el-tab-pane>
				<el-tab-pane label="附表管理" name="b"><Wage_manager v-if="b" /></el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import Sync_salary_index from "@/views/salary_post/new_post/sync_salary/sync_salary_index.vue";
import Insurance_index from "@/views/salary_post/new_post/sync_salary/insurance_index.vue";
import Certificate_index from "@/views/salary_post/new_post/sync_salary/certificate_index.vue";
import Part_time_job from "@/views/salary_post/new_post/sync_salary/part_time_job.vue";
import Wage_visiting from "@/views/salary_post/new_post/sync_salary/wage_visiting.vue";
import Wage_examine from "@/views/salary_post/new_post/sync_salary/wage_examine.vue";
import Wage_manager from "@/views/salary_post/new_post/wage_manager.vue";

export default {
	name: "",
	components: {Sync_salary_index, Header, Insurance_index, Certificate_index, Part_time_job, Wage_visiting, Wage_examine
	, Wage_manager},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			activeName: 'a',
			a: true,
			b: false,
			c: false,
			d: false,
			e: false,
			f: false
		}
	},
	created() {
	},
	methods: {
		handleClick(tab, event){
			if (tab.props.name === 'a'){
				this.a = true
				this.b = false
				this.c = false
				this.d = false
				this.e = false
				this.f = false
			}
			if (tab.props.name === 'b') {
				this.b = true
				this.a = false
				this.c = false
				this.d = false
				this.e = false
				this.f = false
			}
			if (tab.props.name === 'c') {
				this.b = false
				this.a = false
				this.c = true
				this.d = false
				this.e = false
				this.f = false
			}
			if (tab.props.name === 'd') {
				this.b = false
				this.a = false
				this.c = false
				this.d = true
				this.e = false
				this.f = false
			}
			if (tab.props.name === 'e') {
				this.b = false
				this.a = false
				this.c = false
				this.e = true
				this.d = false
				this.f = false
			}
			if (tab.props.name === 'f') {
				this.b = false
				this.a = false
				this.c = false
				this.f = true
				this.e = false
				this.d = false
			}
		}
	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>