<template>
	<div>
		<!--	考勤表-->
		<div id="forms_div2">
			<div style="display: flex">
				<!--	局指选择工资发放标段-->
				<div v-if="GET_BDST === '1'" style="margin-left: 2%">
					<el-select v-model="salary_post" class="m-2" placeholder="请选择工资发放标段" size="large" @change="getExamineUser">
						<el-option
							v-for="item in salary_posts"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</div>
				<div style="margin-left: 2%">
					<el-button @click="saveExamine" type="danger">更新考核系数</el-button>
				</div>
			</div>

			<!--	考核系数表-->
			<div style="margin-top: 1%">
				<vxe-table
					border
					height="700"
					:align="allAlign"
					:column-config="{resizable: true}"
					:row-config="{isHover: true}"
					:edit-config="{trigger: 'click', mode: 'cell'}"
					:data="examine_list">
					<vxe-column type="seq" width="50" fixed="left"></vxe-column>
					<vxe-column field="realName" title="姓名" width="125" fixed="left"></vxe-column>
					<vxe-column field="anquanExamine" title="安全考核系数" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.anquanExamine" type="text" placeholder="请输入安全考核系数"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="zhiliangExamine" title="质量考核系数" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.zhiliangExamine" type="text" placeholder="请输入质量考核系数"></vxe-input>
						</template>
					</vxe-column>
					<vxe-column field="huanbaoExamine" title="环保考核系数" width="180" :edit-render="{}">
						<template #edit="{ row }">
							<vxe-input v-model="row.huanbaoExamine" type="text" placeholder="请输入环保考核系数"></vxe-input>
						</template>
					</vxe-column>
				</vxe-table>
			</div>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import {setNowMonth2, setNowTimes} from "@/utils/time";

export default {
	name: "",
	components: {Header},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			salary_post: '2标',
			salary_posts: [
				{
					value: '2标',
					label: '2标',
				},
				{
					value: '5标',
					label: '5标',
				}
			],
			examine_list: [],
			allAlign: 'center',
			month: setNowMonth2(),
			button_flag: true
		}
	},
	created() {
		this.getExamineUser()
	},
	methods: {
		getExamineUser(){
			if (this.GET_BDST !== '1'){
				this.salary_post = this.GET_BDST
			}
			this.button_flag = true
			this.axios.get('/ucUser/getExamine', (response) => {
				this.examine_list = response.obj
			},{
				bdst: this.GET_BDST,
				salary_post: this.salary_post
			})
		},
		saveExamine(){
			this.axios.post('/wageExamine/updataExamine', (response) => {
				if (response.obj){
					ElMessage.success('更新成功！')
					this.getExamineUser()
				}else {
					ElMessage.error('更新失败，请联系科信部!')
				}
			},{
				bdst: this.GET_BDST,
				salary_post: this.salary_post,
				examines: JSON.stringify(this.examine_list)
			})
		}
	}
}
</script>

<style>
#admindiv1{
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}

#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 750px;
}
</style>